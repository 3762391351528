import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBDataTableV5,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBModal,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBInputGroup,
} from "mdbreact";

class InviteLinkModel extends Component {
  state = {};
  render() {
    const { modal1 } = this.props;
    return (
      <MDBModal backdrop={false} isOpen={modal1} toggle={this.props.toggle(1)}>
        <MDBModalHeader toggle={this.props.toggle(1)}>
          Meeting Info
        </MDBModalHeader>
        <MDBModalBody className="meeting-share-body">
          <p className="text-muted">
            Share this info with people you want in the meeting
          </p>
          <MDBInputGroup
            material
            containerClassName="mb-3 mt-0"
            hint="https://meet.google.com/rsv-cbai-djg"
            disabled
            append={
              <MDBBtn
                color="primary"
                outline
                size="md"
                className="m-0 px-3 py-2 z-depth-0"
              >
                Copy Link
              </MDBBtn>
            }
          />
        </MDBModalBody>
      </MDBModal>
    );
  }
}

export default InviteLinkModel;
