import {
  MEETING_START,
  MEETING_SUCCESS,
  MEETING_FAILURE,
  GET_MEETING_HISTORY_START,
  GET_MEETING_HISTORY_SUCCESS,
  GET_MEETING_HISTORY_FAILURE,
  MEETING_END_START,
  MEETING_END_SUCCESS,
  MEETING_END_FAILURE,
  JOIN_MEETING_START,
  JOIN_MEETING_SUCCESS,
  JOIN_MEETING_FAILURE,
  SEND_CONNECTION_ID_START,
  SEND_CONNECTION_ID_SUCCESS,
  SEND_CONNECTION_ID_FAILURE,
  BEFORE_START_MEETING_START,
  BEFORE_START_MEETING_SUCCESS,
  BEFORE_START_MEETING_FAILURE,
  GET_MEETING_DETAILS,
} from "./ActionConstant";

// Get Meeting Input

export function getMeetingInputData(name, value) {
  return {
    type: GET_MEETING_DETAILS,
    name,
    value,
  };
}

// User MEETING actions.

export function meetingStart(data) {
  return {
    type: MEETING_START,
    data,
  };
}

export function meetingSuccess(data) {
  return {
    type: MEETING_SUCCESS,
    data,
  };
}

export function meetingFailure(error) {
  return {
    type: MEETING_FAILURE,
    error,
  };
}

// Meeting history actions.

export function getMeetingHistoryStart(data) {
  return {
    type: GET_MEETING_HISTORY_START,
    data,
  };
}

export function getMeetingHistorySuccess(data) {
  return {
    type: GET_MEETING_HISTORY_SUCCESS,
    data,
  };
}

export function getMeetingHistoryFailure(error) {
  return {
    type: GET_MEETING_HISTORY_FAILURE,
    error,
  };
}

// Join Meeting  actions.

export function joinMeetingStart(data) {
  return {
    type: JOIN_MEETING_START,
    data,
  };
}

export function joinMeetingSuccess(data) {
  return {
    type: JOIN_MEETING_SUCCESS,
    data,
  };
}

export function joinMeetingFailure(error) {
  return {
    type: JOIN_MEETING_FAILURE,
    error,
  };
}

// Meeting End actions.

export function meetingEndStart(data) {
  return {
    type: MEETING_END_START,
    data,
  };
}

export function meetingEndSuccess(data) {
  return {
    type: MEETING_END_SUCCESS,
    data,
  };
}

export function meetingEndFailure(error) {
  return {
    type: MEETING_END_FAILURE,
    error,
  };
}

// Send Connection ID actions.

export function sendConnectionIdStart(data) {
  return {
    type: SEND_CONNECTION_ID_START,
    data,
  };
}

export function sendConnectionIdSuccess(data) {
  return {
    type: SEND_CONNECTION_ID_SUCCESS,
    data,
  };
}

export function sendConnectionIdFailure(error) {
  return {
    type: SEND_CONNECTION_ID_FAILURE,
    error,
  };
}

// Before start meeting.

export function beforeStartMeetingStart(data) {
  return {
    type: BEFORE_START_MEETING_START,
    data,
  };
}

export function beforeStartMeetingSuccess(data) {
  return {
    type: BEFORE_START_MEETING_SUCCESS,
    data,
  };
}

export function beforeStartMeetingFailure(error) {
  return {
    type: BEFORE_START_MEETING_FAILURE,
    error,
  };
}
