import {
  STORE_CALLS_START,
  STORE_CALLS_SUCCESS,
  STORE_CALLS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  storeCall: {
    data: {},
    loading: true,
    error: false,
  },
};

const CallsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_CALLS_START:
      return {
        ...state,
        storeCall: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case STORE_CALLS_SUCCESS:
      return {
        ...state,
        storeCall: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case STORE_CALLS_FAILURE:
      return {
        ...state,
        storeCall: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default CallsReducer;
