import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBContainer, MDBCol } from "mdbreact";
import SectionContainer from "../helper/SectionContainer";
import {
  getForgotPasswordInputData,
  forgotPasswordStart,
} from "../../store/actions/UserAction";

class ForgotPassword extends Component {
  state = {};
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getForgotPasswordInputData(input.name, input.value));
  };
  handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(forgotPasswordStart());
  };
  render() {
    const inputData = this.props.inputData.forgotPasswordInputData;
    return (
      <div className="sm-padding forgot-password">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5 mx-auto">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Forgot Password</h5>
                    </div>
                    <div className="card-body forgot-password-form">
                      <form>
                        <label
                          htmlFor="defaultFormRegisterEmailEx"
                          className="grey-text"
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          id="defaultFormRegisterEmailEx"
                          className="form-control"
                          name="email"
                          value={inputData.data.email}
                          onChange={this.handleChange}
                        />
                        <br />
                        <div className="text-center mt-4">
                          <button
                            className="btn btn-unique"
                            type="submit"
                            onClick={this.handleForgotPasswordSubmit}
                            disabled={this.props.inputData.buttonDisable}
                          >
                            {this.props.inputData.loadingButtonContent != null
                              ? this.props.inputData.loadingButtonContent
                              : "Submit"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPassword);
