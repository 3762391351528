import React, { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";

import {
  editChangePassword,
  changePasswordStart,
} from "../../../store/actions/UserAction";

class ChangePasswordIndex extends Component {
  handleChangePassword = ({ currentTarget: input }) => {
    this.props.dispatch(editChangePassword(input.name, input.value));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(changePasswordStart());
  };

  render() {
    const { inputData } = this.props.changePassword;
    return (
      <>
        <div className="sm-padding change-password">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5 mx-auto">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Change Password</h5>
                      </div>
                      <div className="card-body change-password-form">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Current Password</label>
                                <input
                                  type="password"
                                  name="old_password"
                                  value={inputData.data.old_password}
                                  className="form-control"
                                  onChange={this.handleChangePassword}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>New Password</label>
                                <input
                                  type="password"
                                  name="password"
                                  value={inputData.data.password}
                                  className="form-control"
                                  onChange={this.handleChangePassword}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Confirm Password</label>
                                <input
                                  type="password"
                                  name="password_confirmation"
                                  value={inputData.data.password_confirmation}
                                  className="form-control"
                                  onChange={this.handleChangePassword}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="update ml-auto mr-auto">
                              <button
                                type="submit"
                                className="btn btn-primary btn-round"
                                onClick={this.handleSubmit}
                                disabled={
                                  this.props.changePassword.buttonDisable
                                }
                              >
                                {this.props.changePassword
                                  .loadingButtonContent != null
                                  ? this.props.changePassword
                                      .loadingButtonContent
                                  : "Change Password"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  changePassword: state.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePasswordIndex);
