import React, { Component } from "react";
import NavBarEmpty from "./header/NavBarEmpty";
import { Notify } from "react-redux-notify";

class LayoutWithoutNav extends Component {
  state = {};
  render() {
    return (
      <div className="wrapper">
        <Notify position="BottomLeft" />
        <div className="main-panel" style={{ height: "100vh", width: "100%" }}>
          <NavBarEmpty />
          <div className="content">
            <div className="row">{React.cloneElement(this.props.children)}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default LayoutWithoutNav;
