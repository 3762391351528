import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";

import {
  fetchUserDetailsStart,
  editUserDetails,
  updateUserDetailsStart,
} from "../../../store/actions/UserAction";

class ProfileIndex extends Component {
  state = {
    loading: true,
    inputData: {},
    imagePreviewUrl: null,
  };
  componentDidMount() {
    if (this.props.userData.profile.loading)
      this.props.dispatch(fetchUserDetailsStart());
  }

  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(editUserDetails(input.name, input.value));
  };

  handleChangeImage = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "picture")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
      setTimeout(() => {
        this.props.dispatch(updateUserDetailsStart(input.files[0]));
      }, 1000);
    }
  };

  updateProfile = (event) => {
    event.preventDefault();
    this.props.dispatch(updateUserDetailsStart());
  };

  render() {
    const userData = this.props.userData.profile;
    if (userData.loading == true) {
      return "Loading..";
    } else {
      return (
        <>
          <div className="profile-sec sm-padding">
            <div className="container">
              <div className="row">
                {/* <div className="col-md-4">
            <div className="card card-user">
              <div className="image">
                <img src="../assets/img/placeholder-profile.jpg" className="profile-img" alt="..." />
              </div>
              <div className="card-body">
                <div className="author">
                  <a href="#">
                    <img
                      className="avatar border-gray"
                      src="../assets/img/mike.jpg"
                      alt="..."
                   />
                    <h5 className="title">{userData.data.name}</h5>
                  </a>
                  <p className="description">{userData.data.email}</p>
                </div>
                <p className="description text-center">
                  {userData.data.description}
                </p>
              </div>
            </div>
                   </div>*/}

                <div className="col-md-12 edit-profile">
                  <div className="row">
                    <div className="col-md-8 mx-auto">
                      <div className="card card-user">
                        <div className="card-header">
                          <h5 className="card-title">Edit Profile</h5>
                        </div>
                        <div className="card-body">
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Home Address"
                                    value={userData.data.name}
                                    name="name"
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Email</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Home Address"
                                    value={userData.data.email}
                                    name="email"
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <img
                                  src={
                                    this.state.imagePreviewUrl != null
                                      ? this.state.imagePreviewUrl
                                      : userData.data.picture
                                  }
                                  className="edit-img"
                                  alt="..."
                                />
                                <div className="form-group">
                                  <label>Image</label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control"
                                    placeholder="Profile image"
                                    name="picture"
                                    onChange={this.handleChangeImage}
                                  />
                                  <span>Please enter .png .jpeg .jpg images only.</span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>About Me</label>
                                  <textarea
                                    className="form-control textarea"
                                    name="description"
                                    onChange={this.handleChange}
                                  >
                                    {userData.data.description}
                                  </textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="update ml-auto mr-auto">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-round"
                                  onClick={this.updateProfile}
                                  disabled={this.props.userData.buttonDisable}
                                >
                                  {this.props.userData.loadingButtonContent !=
                                  null
                                    ? this.props.userData.loadingButtonContent
                                    : "Update Profile"}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToPros = (state) => ({
  userData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withToastManager(ProfileIndex));
