import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "../../Environment";
import {
  MEETING_START,
  GET_MEETING_HISTORY_START,
  MEETING_END_START,
  JOIN_MEETING_START,
  SEND_CONNECTION_ID_START,
  BEFORE_START_MEETING_START,
} from "../actions/ActionConstant";
import {
  meetingSuccess,
  meetingFailure,
  getMeetingHistorySuccess,
  getMeetingHistoryFailure,
  sendConnectionIdStart,
  sendConnectionIdFailure,
  sendConnectionIdSuccess,
  joinMeetingSuccess,
  joinMeetingFailure,
  beforeStartMeetingSuccess,
  beforeStartMeetingFailure,
} from "../actions/MeetingAction";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

function* beforeMeetingStartAPI() {
  try {
    const inputData = yield select(
      (state) => state.meeting.beforeStartMeeting.data
    );
    const response = yield api.postMethod("meetings_limit_check", inputData);
    yield put(beforeStartMeetingSuccess(response.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/start-meeting");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(beforeStartMeetingFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* meetingStartAPI() {
  try {
    const inputData = yield select(
      (state) => state.meeting.meetingInputData.data
    );
    const response = yield api.postMethod("meetings_start", inputData);
    // yield put(meetingSuccess(response.data));
    if (response.data.success) {
      window.location.href = response.data.data.join_response.data;
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(meetingFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* joinMeetingAPI() {
  try {
    const inputData = yield select(
      (state) => state.meeting.joinMeetingInput.data
    );
    const response = yield api.postMethod("meetings_members_join", inputData);
    yield put(joinMeetingSuccess(response.data));
    if (response.data.success) {
      window.location.href = response.data.data.join_response.data;

      // localStorage.setItem("connectionID", response.data.data.connection_id);
      // Do nothing
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      // window.location.assign("/");
    }
  } catch (error) {
    yield put(joinMeetingFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
    // window.location.assign("/");
  }
}

function* getMeetingHistoryAPI() {
  try {
    const response = yield api.postMethod("meetings_index");
    yield put(getMeetingHistorySuccess(response.data.data));
    if (response.data.success) {
      // Do nothing
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getMeetingHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* meetingEndAPI() {
  try {
    const inputData = yield select(
      (state) => state.meeting.meetingSuccess.data.data
    );
    const response = yield api.postMethod("meetings_end", inputData);
    yield put(meetingSuccess(response.data));
  } catch (error) {
    yield put(meetingFailure(error));
  }
}

function* sendConnectionIdAPI() {
  try {
    const inputData = yield select((state) => state.meeting.connectionID.data);
    const response = yield api.postMethod(
      "meetings_connection_update",
      inputData
    );
    yield put(sendConnectionIdSuccess(response.data));
  } catch (error) {
    yield put(sendConnectionIdFailure(error));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(BEFORE_START_MEETING_START, beforeMeetingStartAPI),
  ]);
  yield all([yield takeLatest(MEETING_START, meetingStartAPI)]);
  yield all([yield takeLatest(MEETING_END_START, meetingEndAPI)]);
  yield all([yield takeLatest(JOIN_MEETING_START, joinMeetingAPI)]);
  yield all([
    yield takeLatest(GET_MEETING_HISTORY_START, getMeetingHistoryAPI),
  ]);
  yield all([yield takeLatest(SEND_CONNECTION_ID_START, sendConnectionIdAPI)]);
}
