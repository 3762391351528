import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteAccountStart } from "../../../store/actions/UserAction";

class DeleteAccountIndex extends Component {
  state = {
    password: "",
  };
  handleChange = ({ currentTarget: input }) => {
    this.setState({ password: input.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(deleteAccountStart({ password: this.state.password }));
  };

  render() {
    return (
      <>
        <div className="sm-padding change-password">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5 mx-auto">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Delete Account</h5>
                      </div>
                      <div className="card-body change-password-form">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label> Password</label>
                                <input
                                  type="password"
                                  name="password"
                                  value={this.state.password}
                                  className="form-control"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="update ml-auto mr-auto">
                              <button
                                type="submit"
                                className="btn btn-primary btn-round"
                                onClick={this.handleSubmit}
                                disabled={this.props.users.buttonDisable}
                              >
                                {this.props.users.loadingButtonContent != null
                                  ? this.props.users.loadingButtonContent
                                  : "Delete Account"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  users: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccountIndex);
