import React, { Component } from "react";
import { connect } from "react-redux";

import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { fetchStoreCallsStart } from "../../store/actions/CallsAction";
import SectionContainer from "../helper/SectionContainer";
import "./Home.css";
import { beforeStartMeetingStart } from "../../store/actions/MeetingAction";

class HomeIndex extends Component {
  state = {};
  startMeeting = (event) => {
    event.preventDefault();
    this.props.dispatch(beforeStartMeetingStart());
  };
  render() {
    return (
      <MDBContainer fluid className="sm-padding start-meeting">
        <MDBRow>
          <MDBCol col="6">
            <div className="home-banner-content-1">
              <h2>Premium video meetings.</h2>
              <h2>Now free for everyone.</h2>
              <h5>
                We re-engineered the service we built for secure business
                meetings, Google Meet, to make it free and available for all.
              </h5>
              <MDBBtn
                color="primary"
                onClick={this.startMeeting}
                disabled={this.props.meeting.beforeStartMeeting.buttonDisable}
              >
                {this.props.meeting.beforeStartMeeting.loadingButtonContent !=
                null
                  ? this.props.meeting.beforeStartMeeting.loadingButtonContent
                  : "Start Meeting"}
              </MDBBtn>
              {/* <span class="resp-flex"> or </span>
              <MDBBtn>Join Meeting</MDBBtn> */}
            </div>
          </MDBCol>
          <MDBCol col="6">
            <div className="home-banner-content-2">
              <img src="../assets/img/conference.jpg" className="hom-img"></img>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToPros = (state) => ({
  storeCalls: state.storeCall,
  meeting: state.meeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeIndex);
