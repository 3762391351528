import React, { Component } from "react";
import "./MeetingPagecss.css";

class MeetingPageIndex extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row half-width-content-row">
            <div className="col-md-12 half-width-content-col-1">
              <div className="row-padding-video">
                <div className="scrolling-wrapper-flexbox">
                  <div class="card">
                    <img src="../assets/img/meeting-video.jpg" className="meeting-video-half"></img>
                  </div>
                  <div class="card">
                    <img src="../assets/img/meeting-video.jpg" className="meeting-video-half"></img>
                  </div>
                  <div class="card">
                    <img src="../assets/img/meeting-video.jpg" className="meeting-video-half"></img>
                  </div>
                </div>
              </div>
              <div className="full-width-video">
                <div className="hover-effect">
                  <img src="../assets/img/meeting-video.jpg" className="meeting-video-full"></img>
                <div class="chat-icons-display">
                  <ul className="chat-icons">
                    <li>
                      <a href="#">
                        <img src="../assets/img/mic.svg" className="meeting-font"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="../assets/img/video-camera.svg" className="meeting-font"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="../assets/img/share.svg" className="meeting-font"></img>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="../assets/img/end-call.svg" className="meeting-font"></img>  
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="../assets/img/chat.svg" className="meeting-font"></img>  
                      </a>
                    </li>
                  </ul>
                </div>
                </div>
              </div>
            </div>
            {/*<div className="col-md-12 half-width-content-col-1">
              <div class="card video-chat-box">
                <div class="card-header text-center">
                  <span>Chat Box</span>
                </div>
                <div class="card-body chat-care">
                  <ul class="chat">
                    <li class="agent clearfix">
                      <span class="chat-img left clearfix mx-2">
                        <img
                          src="../assets/img/placeholder-profile.jpg"
                          alt="Agent"
                          class="img-circle"
                        />
                      </span>
                      <div class="chat-body-1 clearfix">
                        <div class="header clearfix">
                          <strong class="primary-font">Beno Darry</strong>
                          <small class="right text-muted">12 mins ago</small>
                        </div>
                        <p>Lorem ipsum dolor sit amet.</p>
                      </div>
                    </li>
                    <li class="admin clearfix">
                      <span class="chat-img right clearfix  mx-2">
                        <img
                          src="../assets/img/placeholder-profile.jpg"
                          alt="Admin"
                          class="img-circle"
                        />
                      </span>
                      <div class="chat-body clearfix">
                        <div class="header clearfix">
                          <small class="left text-muted">13 mins ago</small>
                          <strong class="right primary-font">Jeno Harry</strong>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Curabitur bibendum ornare dolor, quis
                          ullamcorper ligula sodales.
                        </p>
                      </div>
                    </li>
                    <li class="agent clearfix">
                      <span class="chat-img left clearfix mx-2">
                        <img
                          src="../assets/img/placeholder-profile.jpg"
                          alt="Agent"
                          class="img-circle"
                        />
                      </span>
                      <div class="chat-body-1 clearfix">
                        <div class="header clearfix">
                          <strong class="primary-font">Beno darry</strong>
                          <small class="right text-muted">14 mins ago</small>
                        </div>
                        <p>Lorem ipsum dolor sit amet.</p>
                      </div>
                    </li>
                    <li class="admin clearfix">
                      <span class="chat-img right clearfix  mx-2">
                        <img
                          src="../assets/img/placeholder-profile.jpg"
                          alt="Admin"
                          class="img-circle"
                        />
                      </span>
                      <div class="chat-body clearfix">
                        <div class="header clearfix">
                          <small class="left text-muted">15 mins ago</small>
                          <strong class="right primary-font">Jeno Harry</strong>
                        </div>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Curabitur bibendum ornare dolor.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card-footer">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control input-sm"
                      placeholder="Type your message here..."
                    />
                    <span class="input-group-btn">
                      <button class="btn btn-primary">Send</button>
                    </span>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </>
    );
  }
}

export default MeetingPageIndex;
