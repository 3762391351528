import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBIcon } from "mdbreact";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    return (
      <MDBFooter className="font-small theme-footer">
        <MDBContainer
          fluid
          className="text-center text-md-left theme-footer-container"
        >
          <MDBRow>
            <MDBCol md="3">
              <h5 className="title">
                {configuration.get("configData.site_name")}
              </h5>
              <p className="theme-footer-info">
                {configuration.get("configData.tag_name")}
              </p>
            </MDBCol>
            <MDBCol md="3">
              <h5 className="">Discover</h5>
              <ul className="">
                {configuration
                  .get("configData.footer_pages1")
                  .map((static_page, index) => (
                    <li className="list-unstyled" key={static_page.unique_id}>
                      <Link
                        to={`/page/${static_page.unique_id}`}
                        key={static_page.page_id}
                      >
                        {static_page.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </MDBCol>

            <MDBCol md="3">
              <h5 className="">Links</h5>
              <ul className="">
                {configuration
                  .get("configData.footer_pages2")
                  .map((static_page, index) => (
                    <li className="list-unstyled" key={static_page.unique_id}>
                      <Link
                        to={`/page/${static_page.unique_id}`}
                        key={static_page.page_id}
                      >
                        {static_page.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </MDBCol>
            <MDBCol md="3">
              <h5 className="title">Get The Latest App :</h5>
              <ul className="">
                <li className="list-unstyled">
                  <a href={configuration.get("configData.playstore_link")}>
                    <img
                      className="apps-img"
                      src={
                        window.location.origin + "/assets/img/play-store.png"
                      }
                    ></img>{" "}
                  </a>
                </li>

                <li className="list-unstyled">
                  <a href={configuration.get("configData.appstore_link")}>
                    <img
                      className="apps-img"
                      src={window.location.origin + "/assets/img/app-store.png"}
                    ></img>{" "}
                  </a>
                </li>

                <li className="list-unstyled">
                  <a href={configuration.get("configData.facebook_link")}>
                    <MDBIcon fab icon="facebook" className="pr-2" size="2x" />
                  </a>
                  <a href={configuration.get("configData.twitter_link")}>
                    <MDBIcon fab icon="twitter" className="pr-2" size="2x" />
                  </a>
                  <a href={configuration.get("configData.linkedin_link")}>
                    <MDBIcon fab icon="linkedin" className="pr-2" size="2x" />
                  </a>
                  <a href={configuration.get("configData.pinterest_link")}>
                    <MDBIcon fab icon="pinterest" className="pr-2" size="2x" />
                  </a>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid>
            &copy; {new Date().getFullYear()} Copyright:{" "}
            <a href="#">{configuration.get("configData.site_name")}</a>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}

export default Footer;
