import React, { Component } from "react";

import api from "../../Environment";
import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";

import { connect } from "react-redux";

class Logout extends Component {
  state = {};

  componentDidMount() {
    api.postMethod("logout").then((response) => {
      if (response.data.success) {
        console.log("success");
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        this.props.dispatch(createNotification(notificationMessage));
      } else {
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        this.props.dispatch(createNotification(notificationMessage));
      }
    });

    localStorage.removeItem("accessToken");

    localStorage.removeItem("userId");

    localStorage.removeItem("userLoginStatus");

    localStorage.removeItem("user_picture");

    localStorage.removeItem("username");

    localStorage.removeItem("total_followers");

    localStorage.removeItem("total_followings");

    localStorage.removeItem("total_live_videos");

    this.props.history.push("/login");
  }

  render() {
    return "";
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(Logout);
