export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const BEFORE_START_MEETING_START = "BEFORE_START_MEETING_START";
export const BEFORE_START_MEETING_SUCCESS = "BEFORE_START_MEETING_SUCCESS";
export const BEFORE_START_MEETING_FAILURE = "BEFORE_START_MEETING_FAILURE";

export const MEETING_START = "MEETING_START";
export const MEETING_SUCCESS = "MEETING_SUCCESS";
export const MEETING_FAILURE = "MEETING_FAILURE";

export const JOIN_MEETING_START = "JOIN_MEETING_START";
export const JOIN_MEETING_SUCCESS = "JOIN_MEETING_SUCCESS";
export const JOIN_MEETING_FAILURE = "JOIN_MEETING_FAILURE";

export const GET_MEETING_DETAILS = "GET_MEETING_DETAILS";

export const GET_MEETING_HISTORY_START = "GET_MEETING_HISTORY_START";
export const GET_MEETING_HISTORY_SUCCESS = "GET_MEETING_HISTORY_SUCCESS";
export const GET_MEETING_HISTORY_FAILURE = "GET_MEETING_HISTORY_FAILURE";

export const MEETING_END_START = "MEETING_END_START";
export const MEETING_END_SUCCESS = "MEETING_END_SUCCESS";
export const MEETING_END_FAILURE = "MEETING_END_FAILURE";

export const STORE_CALLS_START = "STORE_CALLS_START";
export const STORE_CALLS_SUCCESS = "STORE_CALLS_SUCCESS";
export const STORE_CALLS_FAILURE = "STORE_CALLS_FAILURE";

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_START = "SUBSCRIPTION_PAYMENT_START";
export const SUBSCRIPTION_PAYMENT_SUCCESS = "SUBSCRIPTION_PAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_FAILURE = "SUBSCRIPTION_PAYMENT_FAILURE";

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

export const SEND_CONNECTION_ID_START = "SEND_CONNECTION_ID_START";
export const SEND_CONNECTION_ID_SUCCESS = "SEND_CONNECTION_ID_SUCCESS";
export const SEND_CONNECTION_ID_FAILURE = "SEND_CONNECTION_ID_FAILURE";
