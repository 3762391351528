import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  fetchCardDetailsStart,
  selectDefaultCardStart,
  deleteCardStart,
} from "../../../store/actions/CardsAction";
import AddCardSec from "../../helper/AddCardSec";
import { Elements, StripeProvider } from "react-stripe-elements";
import configuration from "react-global-configuration";

class CardsList extends Component {
  constructor() {
    super();
    this.state = { stripe: null, displayAddCardSection: false };
  }
  componentDidMount() {
    if (this.props.cards.cardDetails.loading)
      this.props.dispatch(fetchCardDetailsStart());
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(
          configuration.get("configData.stripe_publishable_key")
        ),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(
            configuration.get("configData.stripe_publishable_key")
          ),
        });
      });
    }
  }

  cardAddedStatusChange = () => {
    this.setState({ displayAddCardSection: false });
  };

  addCardSection = (event) => {
    event.preventDefault();
    this.setState({ displayAddCardSection: true });
  };

  changeDefaultCard = (event, card) => {
    event.preventDefault();
    console.log("Make default");
    this.props.dispatch(selectDefaultCardStart(card));
  };

  deleteCard = (event, card) => {
    event.preventDefault();

    this.props.dispatch(deleteCardStart(card));
  };
  render() {
    const { cards } = this.props;
    return (
      <div>
        <div className="card-details-sec sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">
                          <i className="far fa-credit-card mr-2"></i>Card
                          Details
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {cards.cardDetails.loading
                            ? "Loading..."
                            : cards.cardDetails.data.cards.length > 0
                            ? cards.cardDetails.data.cards.map((card) => (
                                <div
                                  className="col-md-6"
                                  key={card.user_card_id}
                                >
                                  <div className="card-details-info">
                                    <div className="card-left">
                                      <img
                                        src="../assets/img/credit-card.svg"
                                        alt="card_img"
                                      />
                                    </div>
                                    <div className="card-details">
                                      <h5>XXXX XXXX XXXX {card.last_four}</h5>
                                      {card.is_default === 1 ? (
                                        <p className="m-0">
                                          <div className="default-clr">
                                            Default Card
                                          </div>
                                        </p>
                                      ) : (
                                        <p className="m-0">
                                          <a
                                            href="#"
                                            className="default-clr"
                                            onClick={(event) =>
                                              this.changeDefaultCard(
                                                event,
                                                card
                                              )
                                            }
                                          >
                                            Make Default Card
                                          </a>
                                        </p>
                                      )}

                                      <Link
                                        onClick={(event) =>
                                          this.deleteCard(event, card)
                                        }
                                        to={""}
                                      >
                                        <i className="fa fa-trash pull-right card-del-right"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : "No Data Found"}
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card-details-info">
                              <div className="card-left">
                                <img
                                  src="../assets/img/plus.svg"
                                  alt="card_img"
                                />
                              </div>
                              <div className="add-card-details">
                                <a
                                  className="btn-link"
                                  onClick={this.addCardSection}
                                >
                                  Add Card
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.displayAddCardSection ? (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card-details-info">
                                <StripeProvider stripe={this.state.stripe}>
                                  <Elements>
                                    <AddCardSec
                                      cardAddedStatusChange={
                                        this.cardAddedStatusChange
                                      }
                                    />
                                  </Elements>
                                </StripeProvider>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  cards: state.cards,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CardsList);
