import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import renderHTML from "react-render-html";

class StaticContent extends Component {
  render() {
    const { displayContent } = this.props;
    return (
      <div>
        <div className="static-page-sec sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {displayContent == null ? (
                  "Loading..."
                ) : (
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">{displayContent.title}</h5>
                    </div>
                    <div className="card-body">
                      <div className="main-section">
                        {renderHTML(displayContent.description)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StaticContent;
