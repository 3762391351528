import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBFormInline,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
  MDBIcon,
  MDBNavLink,
  MDBTooltip,
} from "mdbreact";

import configuration from "react-global-configuration";

class NavBarEmpty extends Component {
  state = {
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  closeCollapse = (collID) => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: "" });
  };
  render() {
    const { isOpen, collapseID } = this.state;
    return (
      <MDBNavbar
        className="navbar-theme"
        dark
        expand="md"
        fixed="top"
        scrolling
      >
        <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
          <img
            src={
              configuration.get("configData.site_logo")
                ? configuration.get("configData.site_logo")
                : window.location.origin + "/logo.png"
            }
            className="logo"
          ></img>
          <strong className="align-middle">
            {configuration.get("configData.site_name")}
          </strong>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse("mainNavbarCollapse")} />
        <MDBCollapse id="mainNavbarCollapse" isOpen={collapseID} navbar>
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBNavLink
                exact
                to="/"
                onClick={this.closeCollapse("mainNavbarCollapse")}
                className="navbar-theme-link"
              >
                Home
              </MDBNavLink>
            </MDBNavItem>

            {localStorage.getItem("userLoginStatus") ? (
              <>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse("mainNavbarCollapse")}
                    to="/meetings-history"
                    className="navbar-theme-link"
                  >
                    Meetings
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle
                      color="ins"
                      caret
                      className="nav-profile-dropdown margin-zero "
                    >
                      <img
                        src={
                          window.location.origin + "/assets/img/placeholder.png"
                        }
                        className="nav-profile-img"
                      ></img>{" "}
                      {localStorage.getItem("username")}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="nav-profile-list">
                      <MDBDropdownItem>
                        <MDBIcon icon="user" className="" />
                        <Link to="/profile">Profile</Link>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <MDBIcon icon="phone" className="" />
                        <Link to="/change-password">Change Password</Link>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <MDBIcon icon="phone" className="" />
                        <Link to="/delete-account">Delete Account</Link>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <MDBIcon icon="phone" className="" />
                        <Link to="/cards">Cards</Link>
                      </MDBDropdownItem>

                      <MDBDropdownItem>
                        <MDBIcon icon="gem" className="" />
                        <Link to="/subscriptions">Subscriptions</Link>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <MDBIcon icon="phone" className="" />
                        <Link to="/meetings-history">Meetings</Link>
                      </MDBDropdownItem>

                      <MDBDropdownItem>
                        <MDBIcon icon="sign-out-alt" className="mr-2" />
                        <Link to={"/logout"}>Logout</Link>
                      </MDBDropdownItem>

                      {/* <MDBDropdownItem divider /> */}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
              </>
            ) : (
              <>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse("mainNavbarCollapse")}
                    to="/login"
                    className="navbar-theme-btn"
                  >
                    Login
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse("mainNavbarCollapse")}
                    to="/register"
                    className="navbar-theme-btn"
                  >
                    Register
                  </MDBNavLink>
                </MDBNavItem>
              </>
            )}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default NavBarEmpty;
