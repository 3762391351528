import React, { Component } from "react";
import VideoRoomComponent from "./VideoRoomComponent";
import api from "../../../Environment";
import MeetingStart from "../../meeting/MeetingStart";
import InviteLinkModel from "./InviteLinkModel";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { createNotification } from "react-redux-notify";
import { getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { Notify } from "react-redux-notify";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBDataTableV5,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBModal,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
  MDBInputGroup,
} from "mdbreact";

class MainPage extends Component {
  state = {
    meetingData: null,
    loadingMeeting: true,
    loadStartMeeting: false,
    model1: false,
    copyButtonText: "Copy Link",
  };
  componentDidMount() {
    // this.getMeetingDetailsAPI();
    if (this.props.location.state != null) {
      this.setState({ loadingMeeting: false });
      const modalNumber = `modal1`;
      this.setState({
        [modalNumber]: !this.state[modalNumber],
      });
    } else {
      this.setState({ loadStartMeeting: true });
    }
  }

  getMeetingDetailsAPI = () => {
    api
      .postMethod("meetings_members", {
        meeting_unique_id: this.props.match.params.meeting_unique_id,
      })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            meetingData: response.data.data,
            loadingMeeting: false,
          });
        } else {
        }
      });
  };
  toggle = (nr) => () => {
    const modalNumber = `modal${nr}`;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  onCopy = () => {
    this.setState({ copyButtonText: "Copied!" });
    const notificationMessage = getSuccessNotificationMessage("Copied");
    this.props.dispatch(createNotification(notificationMessage));
  };
  render() {
    if (this.props.location.state != null) {
      const { meetingDetails, mainStreamer } = this.props.location.state;
      const { modal1 } = this.state;
      return (
        <>
          <Notify position="BottomLeft" />
          <MDBModal
            className="center-modal"
            backdrop={false}
            isOpen={modal1}
            toggle={this.toggle(1)}
          >
            <MDBModalHeader toggle={this.toggle(1)}>
              Invite Participants
            </MDBModalHeader>
            <MDBModalBody className="meeting-share-body">
              <p className="text-muted">
                Share this info with people you want in the meeting
              </p>
              <MDBInputGroup
                material
                containerClassName="mb-3 mt-0"
                value={window.location.href}
                disabled
                append={
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={window.location.href}
                  >
                    <MDBBtn
                      color="primary"
                      outline
                      size="md"
                      className="m-0 px-3 py-2 z-depth-0"
                    >
                      {this.state.copyButtonText}
                    </MDBBtn>
                  </CopyToClipboard>
                }
              />
            </MDBModalBody>
          </MDBModal>
          <VideoRoomComponent
            sessionName={meetingDetails.data.data.meeting_unique_id}
            user={meetingDetails.data.data.username}
            mainStreamer={mainStreamer}
            {...this.props}
          />
        </>
      );
    } else {
      return (
        <MeetingStart meetingName={this.props.match.params.meeting_unique_id} />
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(MainPage);
