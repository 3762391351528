import React, { Component, useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";

import "./Settings.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
class SettingsIndex extends Component {
  state = {};

  render() {
    return (
      <MDBContainer fluid className="sm-padding settings-section">
        {/* <MDBRow> */}
        <div className="settings-card">
          <Link to="/profile">
            <div className="settings-round c-1">
              <i className="fas fa-user"></i>
            </div>
            <h5 className="settings-text">View Profile</h5>
          </Link>
        </div>
        <div className="settings-card">
          <Link to="/cards">
            <div className="settings-round c-2">
              <i className="fas fa-credit-card"></i>
            </div>
            <h5 className="settings-text">Cards</h5>
          </Link>
        </div>

        <div className="settings-card">
          <Link to="/meetings-history">
            <div className="settings-round c-3">
              <i className="fas fa-phone"></i>
            </div>
            <h5 className="settings-text">Meetings</h5>
          </Link>
        </div>

        <div className="settings-card">
          <Link to="/subscriptions">
            <div className="settings-round c-4">
              <i className="fas fa-shopping-bag"></i>
            </div>
            <h5 className="settings-text">Subscriptions</h5>
          </Link>
        </div>

        <div className="settings-card">
          <Link to="/myplans">
            <div className="settings-round c-5">
              <i className="fas fa-list"></i>
            </div>
            <h5 className="settings-text">My Plans</h5>
          </Link>
        </div>
        <div className="settings-card">
          <Link to="/change-password">
            <div className="settings-round c-6">
              <i className="fas fa-key"></i>
            </div>
            <h5 className="settings-text">Change Password</h5>
          </Link>
        </div>
        <div className="settings-card">
          <Link to="/delete-account">
            <div className="settings-round c-7">
              <i className="fas fa-trash"></i>
            </div>
            <h5 className="settings-text">Delete Account</h5>
          </Link>
        </div>
      </MDBContainer>
    );
  }
}

export default SettingsIndex;
