import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBContainer, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";
import SectionContainer from "../helper/SectionContainer";
import {
  getLoginInputData,
  userLoginStart,
} from "../../store/actions/UserAction";

class LoginIndex extends Component {
  state = {};
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getLoginInputData(input.name, input.value));
  };
  handleLoginSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(userLoginStart());
  };
  render() {
    const inputData = this.props.inputData.loginInputData;
    return (
      <div className="sm-padding login">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5 mx-auto">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Login</h5>
                    </div>
                    <div className="card-body login-form">
                      <form>
                        <label
                          htmlFor="defaultFormRegisterEmailEx"
                          className="grey-text"
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          id="defaultFormRegisterEmailEx"
                          className="form-control"
                          name="email"
                          value={inputData.data.email}
                          onChange={this.handleChange}
                        />
                        <br />
                        <label
                          htmlFor="defaultFormRegisterPasswordEx"
                          className="grey-text"
                        >
                          Your password
                        </label>
                        <input
                          type="password"
                          id="defaultFormRegisterPasswordEx"
                          className="form-control"
                          value={inputData.data.password}
                          onChange={this.handleChange}
                          name="password"
                        />
                        <p className="font-small grey-text d-flex justify-content-center mt-4">
                          Forgot
                          <Link
                            to={"/forgot-password"}
                            className="dark-grey-text ml-1 font-weight-bold"
                          >
                            Password?
                          </Link>
                        </p>
                        <div className="text-center mt-3">
                          <button
                            className="btn btn-unique"
                            type="submit"
                            onClick={this.handleLoginSubmit}
                            disabled={this.props.inputData.buttonDisable}
                          >
                            {this.props.inputData.loadingButtonContent != null
                              ? this.props.inputData.loadingButtonContent
                              : "Login"}
                          </button>
                        </div>
                      </form>
                      <p className="font-small grey-text mt-3 d-flex justify-content-center">
                        Don't have an account?
                        <Link
                          to={"/register"}
                          className="dark-grey-text ml-1 font-weight-bold"
                        >
                          Sign up
                        </Link>
                      </p>
                    </div>
                  </div>
                  {/* <div className="custom-control custom-checkbox pl-3">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      value=""
                      id="invalidCheck"
                      required
                    />
                    <label className="custom-control-label" htmlFor="invalidCheck">
                      ForGot Password
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);
