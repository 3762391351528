import {
  STORE_CALLS_START,
  STORE_CALLS_SUCCESS,
  STORE_CALLS_FAILURE,
} from "./ActionConstant";

// Get user details actions.

export function fetchStoreCallsStart(data) {
  return {
    type: STORE_CALLS_START,
    data,
  };
}

export function fetchStoreCallsSuccess(data) {
  return {
    type: STORE_CALLS_SUCCESS,
    data,
  };
}

export function fetchStoreCallsFailure(error) {
  return {
    type: STORE_CALLS_FAILURE,
    error,
  };
}
