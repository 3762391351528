import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMySubscriptionStart } from "../../store/actions/SubscriptionAction";

class UserPurchaseHistory extends Component {
  componentDidMount() {
    this.props.dispatch(fetchMySubscriptionStart());
  }
  render() {
    const { mySubscription } = this.props.subscriptions;
    return (
      <div className="invoice-history-sec sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">My Plans</h4>
                </div>
                {mySubscription.loading ? (
                  "Loading..."
                ) : mySubscription.data.length > 0 ? (
                  <div className="card-body">
                    <div className="table-responsive invoice-history-table">
                      <table className="table">
                        <thead className=" text-primary">
                          <th>S.no</th>
                          <th>Payment ID</th>
                          <th>Title</th>
                          <th>Plan Type</th>
                          <th>Amount</th>
                          <th>No of Users</th>
                          <th>No Of Hrs</th>
                          <th>Expiry Date</th>
                        </thead>
                        <tbody>
                          {mySubscription.data.map((sub, i) => (
                            <tr key={sub.user_subscription_id}>
                              <td>{i + 1}</td>
                              <td>{sub.payment_id}</td>
                              <td>{sub.title}</td>
                              <td>{sub.plan_text}</td>
                              <td>{sub.amount_formatted}</td>
                              <td>{sub.no_of_users_formatted}</td>
                              <td>{sub.no_of_hrs_formatted}</td>
                              <td>{sub.expiry_date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  "No Data Found"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(UserPurchaseHistory);
