import React, { Component } from "react";
import { MDBContainer, MDBCol } from "mdbreact";

import { connect } from "react-redux";
import {
  getRegisterInputData,
  userRegisterStart,
} from "../../store/actions/UserAction";

import SectionContainer from "../helper/SectionContainer";
import { Link } from "react-router-dom";

class RegisterIndex extends Component {
  state = {};
  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getRegisterInputData(input.name, input.value));
  };
  handleRegisterSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(userRegisterStart());
  };

  render() {
    const inputData = this.props.inputData.registerInputData;
    return (
      <div className="sm-padding register">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5 mx-auto">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Register</h5>
                    </div>
                    <div className="card-body register-form">
                      <form>
                        <label
                          htmlFor="defaultFormRegisterNameEx"
                          className="grey-text"
                        >
                          Your name
                        </label>
                        <input
                          type="text"
                          id="defaultFormRegisterNameEx"
                          className="form-control"
                          name="name"
                          value={inputData.data.name}
                          onChange={this.handleChange}
                        />
                        <br />
                        <label
                          htmlFor="defaultFormRegisterEmailEx"
                          className="grey-text"
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          id="defaultFormRegisterEmailEx"
                          className="form-control"
                          name="email"
                          value={inputData.data.email}
                          onChange={this.handleChange}
                        />
                        <br />
                        <label
                          htmlFor="defaultFormRegisterPasswordEx"
                          className="grey-text"
                        >
                          Your password
                        </label>
                        <input
                          type="password"
                          id="defaultFormRegisterPasswordEx"
                          className="form-control"
                          name="password"
                          value={inputData.data.password}
                          onChange={this.handleChange}
                        />
                        <div className="text-center mt-4">
                          <button
                            className="btn btn-unique"
                            type="submit"
                            onClick={this.handleRegisterSubmit}
                            disabled={this.props.inputData.buttonDisable}
                          >
                            {this.props.inputData.loadingButtonContent != null
                              ? this.props.inputData.loadingButtonContent
                              : "Register"}
                          </button>
                        </div>
                      </form>
                      <p className="font-small grey-text mt-3 d-flex justify-content-center">
                        Do you have an account
                        <Link
                          to={"/login"}
                          className="dark-grey-text ml-1 font-weight-bold"
                        >
                          Log In
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterIndex);
