import React, { Component } from "react";
import { connect } from "react-redux";
import { getMeetingHistoryStart } from "../../store/actions/MeetingAction";

class MeetingHistory extends Component {
  componentDidMount() {
    this.props.dispatch(getMeetingHistoryStart());
  }
  render() {
    const { meetingHistory } = this.props.meeting;
    return (
      <div className="invoice-history-sec sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Meeting History</h4>
                </div>
                {meetingHistory.loading ? (
                  "Loading..."
                ) : meetingHistory.data.length > 0 ? (
                  <div className="card-body">
                    <div className="table-responsive invoice-history-table">
                      <table className="table">
                        <thead className=" text-primary">
                          <th>Sl.no</th>
                          <th>Meeting Name</th>
                          <th>Number of Users</th>
                          <th>Call duration</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                        </thead>
                        <tbody>
                          {meetingHistory.data.map((meeting, i) => (
                            <tr key={meeting.user_meetingscription_id}>
                              <td>{i + 1}</td>
                              <td>{meeting.meeting_name}</td>
                              <td>{meeting.no_of_users}</td>
                              <td>{meeting.call_duration}</td>
                              <td>{meeting.start_time}</td>
                              <td>{meeting.end_time}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (

                   <div className="row meeting-no-data">
                      No Data Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  meeting: state.meeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MeetingHistory);
