import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
} from "mdbreact";
import "./Meeting.css";
import SectionContainer from "../helper/SectionContainer";
import { connect } from "react-redux";
import {
  getMeetingInputData,
  meetingStart,
  joinMeetingStart,
} from "../../store/actions/MeetingAction";
import { Redirect } from "react-router";
import { OpenVidu } from "openvidu-browser";
import { Prompt } from "react-router";
import { Notify } from "react-redux-notify";
import { Link } from "react-router-dom";

let OV = new OpenVidu();
let publisher;
class MeetingStart extends Component {
  state = {};
  componentDidMount() {
    if (this.props.meetingName != undefined) {
      this.props.dispatch(
        getMeetingInputData("meeting_name", this.props.meetingName)
      );
    }
    this.displayVideo();
  }

  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(getMeetingInputData(input.name, input.value));
  };
  handleMeetingSubmit = (event) => {
    event.preventDefault();
    if (this.props.meetingName != undefined) {
      this.props.dispatch(
        joinMeetingStart({
          meeting_unique_id: this.props.meetingName,
          username: this.props.inputData.meetingInputData.data.username,
        })
      );
    } else {
      this.props.dispatch(meetingStart());
    }
  };
  displayVideo = () => {
    let myHtmlTarget = document.getElementById("myHtmlTarget");
    console.log("msdfasf", myHtmlTarget);

    let FRAME_RATE = 10;

    OV.getUserMedia({
      audioSource: false,
      videoSource: undefined,
      resolution: "1280x720",
      frameRate: FRAME_RATE,
    }).then((mediaStream) => {
      let videoTrack = mediaStream.getVideoTracks()[0];
      // let video = document.createElement("video");
      myHtmlTarget.srcObject = new MediaStream([videoTrack]);

      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      ctx.filter = "grayscale(100%)";

      myHtmlTarget.addEventListener("play", () => {
        let loop = () => {
          if (!myHtmlTarget.paused && !myHtmlTarget.ended) {
            ctx.drawImage(myHtmlTarget, 0, 0, 300, 170);
            setTimeout(loop, 1000 / FRAME_RATE); // Drawing at 10 fps
          }
        };
        loop();
      });
      myHtmlTarget.play();

      let grayVideoTrack = canvas.captureStream(FRAME_RATE).getVideoTracks()[0];

      publisher = OV.initPublisher(myHtmlTarget, {
        audioSource: false,
        videoSource: grayVideoTrack,
      });
    });
  };

  render() {
    const inputData = this.props.inputData.meetingInputData;
    return (
      <MDBContainer fluid className="">
        <Notify position="BottomLeft" />
        <Prompt
          message={(location, action) => {
            console.log("action", action);
            if (action === "PUSH") {
              const r = window.confirm("Do you really want to leave?");
              if (r == true) {
                window.location.assign(location.pathname);
              }
            }
          }}
        />

        {this.props.inputData.meetingSuccess.loading != true ? (
          this.props.inputData.meetingSuccess.data.success == true ? (
            <></>
         // <Redirect
          //   to={{
          //     pathname: `/video-conference/${this.props.inputData.meetingSuccess.data.data.meeting_unique_id}`,
          //     state: {
          //       meetingDetails: this.props.inputData.meetingSuccess,
          //       mainStreamer:
          //         this.props.meetingName == undefined ? true : false,
          //     },
          //   }}
          // />
          ) :
          
          null
        ) : null}
        <div className="meeting-section sm-padding">
          <MDBRow className="meeting-section-content">
            <MDBCol col="6">
              <div className="meeting-section-img">
                <video id="myHtmlTarget"></video>
                {/* <img src="../assets/img/camera-placeholder.jpg"></img> */}
              </div>
            </MDBCol>
            <MDBCol col="6">
              <SectionContainer className="meeting-section-form">
                <form className="meeting-form">
                  <h3 className="h3 text-center mb-4">Meeting Info</h3>
                  <p className="text-muted text-center">
                    Let's start the Meeting
                  </p>
                  <p className="text-muted text-center">
                    Enjoy streaming without any interruption with UZoom
                  </p>
                  <div className="grey-text">
                    <MDBInput
                      label="Meeting Name"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      name="meeting_name"
                      value={
                        this.props.meetingName
                          ? this.props.meetingName
                          : inputData.data.meeting_name
                      }
                      onChange={this.handleChange}
                      disabled={
                        this.props.meetingName != undefined ? true : false
                      }
                    />
                    <MDBInput
                      label="Your Name"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      name="username"
                      value={inputData.data.username}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="text-center">
                    <MDBBtn
                      color="primary"
                      onClick={this.handleMeetingSubmit}
                      disabled={this.props.inputData.buttonDisable}
                    >
                      {" "}
                      {this.props.inputData.loadingButtonContent != null
                        ? this.props.inputData.loadingButtonContent
                        : this.props.meetingName
                        ? "Join Meeting Now"
                        : "Start Meeting Now"}
                    </MDBBtn>
                  </div>
                </form>
              </SectionContainer>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.meeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MeetingStart);
