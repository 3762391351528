import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchStoreCallsSuccess,
  fetchStoreCallsFailure,
} from "../actions/CallsAction";
import api from "../../Environment";
import { STORE_CALLS_START } from "../actions/ActionConstant";

// function* changePasswordAPI() {
//   try {
//     const inputData = yield select(
//       (state) => state.changePassword.changePasswordInputData.data
//     );
//     console.log("Testing change password  Data", inputData);
//     const response = yield api.postMethod("STORE_CALLS", inputData);
//     console.log("Testing api response", response);
//     yield put(changePasswordSuccess(response.data.data));
//   } catch (error) {
//     yield put(changePasswordFailure(error));
//   }
// }

function* getStoreCallsAPI() {
  try {
    const response = yield api.postMethod("store_calls_history");
    console.log("Testing api response", response);
    yield put(fetchStoreCallsSuccess(response.data.data));
  } catch (error) {
    yield put(fetchStoreCallsFailure(error));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(STORE_CALLS_START, getStoreCallsAPI)]);
}
