import React, { Component } from "react";
import api from "../../Environment";
import StaticContent from "../StaticPage/StaticContent";

class StaticIndex extends Component {
  state = {
    pageData: null,
    loadingPageData: true,
    active: null,
    displayContent: null,
    currentPageTitle: null,
  };
  componentDidMount() {
    this.setState({ currentPageTitle: this.props.match.params.title });
    setTimeout(() => {
      this.getPageDetails();
    }, 1000);
  }
  componentWillReceiveProps(props) {
    this.setState({ currentPageTitle: props.match.params.title });
    setTimeout(() => {
      this.getPageDetails();
    }, 1000);
  }

  getPageDetails = () => {
    api.postMethod("pages_list").then((response) => {
      if (response.data.success) {
        this.setState({
          pageData: response.data.data,
          loadingPageData: false,
        });
        setTimeout(() => {
          if (this.state.pageData.length > 0) {
            var currentPageSet = false;

            this.state.pageData.map((pageDetails, index) => {
              if (pageDetails.unique_id == this.state.currentPageTitle) {
                this.setState({
                  active: pageDetails.page_type,
                  displayContent: pageDetails,
                });
                currentPageSet = true;
              }
            });
            if (currentPageSet == false) {
              this.setState({
                active: this.state.pageData[0].page_type,
                displayContent: this.state.pageData[0],
              });
            }
          }
        }, 1000);
      } else {
      }
    });
  };

  changePage = (event, page) => {
    event.preventDefault();
    this.setState({ displayContent: page });
  };
  render() {
    const { pageData, loadingPageData, displayContent } = this.state;

    return (
      <div>
        <StaticContent displayContent={displayContent} />
      </div>
    );
  }
}

export default StaticIndex;
