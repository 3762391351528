import {
  MEETING_START,
  MEETING_SUCCESS,
  MEETING_FAILURE,
  GET_MEETING_HISTORY_START,
  GET_MEETING_HISTORY_SUCCESS,
  GET_MEETING_HISTORY_FAILURE,
  MEETING_END_START,
  MEETING_END_SUCCESS,
  MEETING_END_FAILURE,
  JOIN_MEETING_START,
  JOIN_MEETING_SUCCESS,
  JOIN_MEETING_FAILURE,
  GET_MEETING_DETAILS,
  SEND_CONNECTION_ID_START,
  SEND_CONNECTION_ID_SUCCESS,
  SEND_CONNECTION_ID_FAILURE,
  BEFORE_START_MEETING_START,
  BEFORE_START_MEETING_SUCCESS,
  BEFORE_START_MEETING_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  beforeStartMeeting: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  meetingInputData: {
    data: {},
    loading: true,
    error: false,
  },
  joinMeetingInput: {
    data: {},
    loading: true,
    error: false,
  },
  meetingSuccess: {
    data: {},
    loading: true,
    error: false,
  },
  meetingHistory: {
    data: {},
    loading: true,
    error: false,
  },
  meetingEnd: {
    data: {},
    loading: true,
    error: false,
  },
  joinMeeting: {
    data: {},
    loading: true,
    error: false,
  },
  connectionID: {
    data: {},
    loading: true,
    error: false,
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const MeetingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEFORE_START_MEETING_START:
      return {
        ...state,
        beforeStartMeeting: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case BEFORE_START_MEETING_SUCCESS:
      return {
        ...state,
        beforeStartMeeting: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case BEFORE_START_MEETING_FAILURE:
      return {
        ...state,
        beforeStartMeeting: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case MEETING_START:
      return {
        ...state,
        meetingInputData: {
          data: {
            ...state.meetingInputData.data,
          },
          loading: true,
          error: false,
        },
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };
    case MEETING_SUCCESS:
      return {
        ...state,
        meetingSuccess: {
          data: action.data,
          loading: false,
          error: false,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case MEETING_FAILURE:
      return {
        ...state,
        meetingInputData: {
          data: {
            ...state.meetingInputData.data,
          },
          loading: false,
          error: action.error,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case GET_MEETING_DETAILS:
      return {
        ...state,
        meetingInputData: {
          loading: false,
          error: false,
          data: {
            ...state.meetingInputData.data,
            [action.name]: action.value,
          },
        },
      };
    case GET_MEETING_HISTORY_START:
      return {
        ...state,
        meetingHistory: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_MEETING_HISTORY_SUCCESS:
      return {
        ...state,
        meetingHistory: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_MEETING_HISTORY_FAILURE:
      return {
        ...state,
        meetingHistory: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case JOIN_MEETING_START:
      return {
        ...state,
        joinMeetingInput: {
          data: action.data,
        },
        meetingSuccess: {
          data: {},
          loading: true,
          error: false,
        },
        loading: true,
        buttonDisable: true,
        loadingButtonContent: "Loading please wait",
      };
    case JOIN_MEETING_SUCCESS:
      return {
        ...state,
        meetingSuccess: {
          data: action.data,
          loading: false,
          error: false,
        },
        loading: false,
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case JOIN_MEETING_FAILURE:
      return {
        ...state,
        meetingSuccess: {
          data: {},
          loading: false,
          error: action.error,
        },
        buttonDisable: false,
        loadingButtonContent: null,
      };
    case MEETING_END_START:
      return {
        ...state,
        meetingSuccess: {
          ...state.meetingSuccess,
        },
        meetingEnd: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case MEETING_END_SUCCESS:
      return {
        ...state,
        meetingEnd: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case MEETING_END_FAILURE:
      return {
        ...state,
        meetingEnd: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case SEND_CONNECTION_ID_START:
      return {
        ...state,
        connectionID: {
          data: {
            connection_id: action.data,
            meeting_id: state.meetingSuccess.data.data.meeting_id,
          },
          loading: true,
          error: false,
        },
      };
    case SEND_CONNECTION_ID_SUCCESS:
      return {
        ...state,
        connectionID: {
          data: { ...state.connectionID.data, success: action.data },
          loading: false,
          error: false,
        },
      };
    case SEND_CONNECTION_ID_FAILURE:
      return {
        ...state,
        connectionID: {
          data: { ...state.connectionID.data },
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default MeetingReducer;
