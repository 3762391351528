import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
} from "mdbreact";
import "./Meeting.css";
import SectionContainer from "../helper/SectionContainer";
import { joinMeetingStart } from "../../store/actions/MeetingAction";

class JoinMeetingIndex extends Component {
  state = {};
  componentDidMount() {
    this.props.dispatch(
      joinMeetingStart({
        meeting_unique_id: this.props.match.params.meeting_unique_id,
        username: localStorage.getItem("username")
          ? localStorage.getItem("username")
          : "User",
      })
    );
    this.handleMeetingSubmit();
  }

  handleMeetingSubmit = () => {
    // this.props.dispatch(
    //   joinMeetingStart({
    //     meeting_unique_id: this.props.match.params.meeting_unique_id,
    //     username: localStorage.getItem("username")
    //       ? localStorage.getItem("username")
    //       : "User",
    //   })
    // );
  };

  render() {
    return <>The Meeting is ended.</>;
  }
}

const mapStateToPros = (state) => ({
  inputData: state.meeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(JoinMeetingIndex);
