import React, { Component } from "react";

import { createBrowserHistory as createHistory } from "history";

import { Router, Switch, Route, Redirect } from "react-router-dom";

import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import MainLayout from "../layouts/MainLayout";
import HomeIndex from "../Home/HomeIndex";
import LoginIndex from "../Auth/LoginIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import ChangePasswordIndex from "../Accounts/ChangePassword/ChangePasswordIndex";
import OtherUserDetails from "../Home/OtherUserDetails";
import UserPurchaseHistory from "../Home/UserPurchaseHistory";
import Logout from "../Auth/Logout";
import VideoRoomComponent from "../conference/components/VideoRoomComponent";
import RegisterIndex from "../Auth/RegisterIndex";
import MeetingStart from "../meeting/MeetingStart";

import SubscriptionsList from "../Subscriptions/SubscriptionsList";
import SubscriptionsHistory from "../Subscriptions/SubscriptionsHistory";
import SubscriptionsInvoice from "../Subscriptions/SubscriptionsInvoice";
import MeetingHistory from "../meeting/MeetingHistory";
import MainPage from "../conference/components/MainPage";
import CardsList from "../Accounts/Cards/CardsList";
import StaticIndex from "../StaticPage/StaticIndex";
import DeleteAccountIndex from "../Accounts/DeleteAccount/DeleteAccountIndex";
import LayoutWithoutNav from "../layouts/LayoutWithoutNav";
import configuration from "react-global-configuration";
import { Helmet } from "react-helmet";
import { apiConstants } from "../Constant/constants";
import SettingsIndex from "../Accounts/Settings/SettingsIndex";
import MeetingPageIndex from "../MeetingPage/MeetingPageIndex";
import BBBMeetingStart from "../meeting/BBBMeetingStart";
import JoinMeetingIndex from "../meeting/JoinMeetingIndex";

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
    this.fetchConfig();
  }

  async fetchConfig() {
    const response = await fetch(apiConstants.settingsUrl);
    const configValue = await response.json();

    configuration.set({ configData: configValue.data }, { freeze: false });
    this.setState({ configLoading: false });

    // $("#google_analytics").html(
    //   configuration.get("configData.google_analytics")
    // );

    // $("#header_scripts").html(configuration.get("configData.header_scripts"));

    // $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        <div className="wrapper">
          <div className="loader-warpper">
            <div id="loader" className="page-loader">
              <p>If I’m not back in five minutes, just wait longer.</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
          <meta
            name="description"
            content={configuration.get("configData.meta_description")}
          ></meta>
          <meta
            name="keywords"
            content={configuration.get("configData.meta_keywords")}
          ></meta>
          <meta
            name="author"
            content={configuration.get("configData.meta_author")}
          ></meta>

         
          <meta property="og:title" content={configuration.get("configData.meta_title")}></meta>
          <meta property="og:description" content={configuration.get("configData.meta_description")}></meta>
          <meta property="og:image" content={configuration.get("configData.site_logo")}></meta>

          <meta itemprop="name" content={configuration.get("configData.site_name")}></meta>
          <meta itemprop="description" content={configuration.get("configData.meta_description")}></meta>
          <meta itemprop="image" content={configuration.get("configData.site_logo")}></meta>


          <meta property="twitter:card" content={configuration.get("configData.meta_description")}></meta>
          <meta property="twitter:url" content={configuration.get("configData.twitter_link")}></meta>
          <meta property="twitter:title" content={configuration.get("configData.meta_title")}></meta>
          <meta property="twitter:description" content={configuration.get("configData.meta_description")}></meta>




        </Helmet>

        <Switch>
          <AppRoute
            path={"/"}
            component={HomeIndex}
            exact
            layout={MainLayout}
          />

          <AppRoute
            path={"/login"}
            component={LoginIndex}
            layout={MainLayout}
          />
          <AppRoute
            path={"/register"}
            component={RegisterIndex}
            layout={MainLayout}
          />
          <AppRoute
            path={"/forgot-password"}
            component={ForgotPassword}
            layout={MainLayout}
          />
          <AppRoute
            path={"/settings"}
            component={SettingsIndex}
            layout={MainLayout}
          />
          <AppRoute
            path={"/page/:title"}
            component={StaticIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={ProfileIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePasswordIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delete-account"}
            component={DeleteAccountIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards"}
            component={CardsList}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscriptions"}
            component={SubscriptionsList}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscriptions-invoice/:id"}
            component={SubscriptionsInvoice}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/myplans"}
            component={UserPurchaseHistory}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/start-meeting"}
            component={MeetingStart}
            layout={MainLayout}
          />

          {/* <PrivateRoute
            authentication={this.state.authentication}
            path={"/start-meeting"}
            component={BBBMeetingStart}
            layout={MainLayout}
          /> */}
          <Route
            // authentication={this.state.authentication}
            path={"/video-conference/:meeting_unique_id"}
            component={MainPage}
          />
          <AppRoute
            path={"/meetings-history"}
            component={MeetingHistory}
            layout={MainLayout}
          />
          <AppRoute
            path={"/join/:meeting_unique_id"}
            component={JoinMeetingIndex}
            layout={MainLayout}
          />
          <Route path={"/meeting-page"} component={MeetingPageIndex} />

          <AppRoute path={"/logout"} component={Logout} layout={MainLayout} />
        </Switch>
      </>
    );
  }
}

export default App;
