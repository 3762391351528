import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import "./Subscription.css";
import SectionContainer from "../helper/SectionContainer";
import { Elements, StripeProvider } from "react-stripe-elements";
import {
  fetchSingleSubscriptionStart,
  subscriptionPaymentStart,
} from "../../store/actions/SubscriptionAction";
import { fetchCardDetailsStart } from "../../store/actions/CardsAction";
import AddCardSec from "../helper/AddCardSec";
import configuration from "react-global-configuration";

class SubscriptionInvoice extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    if (this.props.location.state != null) {
      this.setState({ loading: false });
    } else {
      this.props.dispatch(
        fetchSingleSubscriptionStart({
          subscription_id: this.props.match.params.id,
        })
      );
    }
    if (this.props.cards.cardDetails.loading)
      this.props.dispatch(fetchCardDetailsStart());
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(
          configuration.get("configData.stripe_publishable_key")
        ),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(
            configuration.get("configData.stripe_publishable_key")
          ),
        });
      });
    }
  }
  handleSubmit = (event, subscription) => {
    this.props.dispatch(
      subscriptionPaymentStart({
        subscription_id: subscription.subscription_id,
      })
    );
  };
  render() {
    let subscriptionData;
    if (this.props.location.state != null) {
      subscriptionData = this.props.location.state;
    } else {
      subscriptionData = this.props.subscriptions.singleSubscription.data;
    }

    return (
      <div>
        <div className="invoice-sec sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <div className="card card-user">
                      <div className="card-header">
                        <h5 className="card-title">Invoice</h5>
                      </div>
                      {this.props.subscriptions.singleSubscription.loading !=
                      true ? (
                        <div className="card-body">
                          <div className="head">
                            <i className="fas fa-file-invoice mr-2"></i>
                            <span className="head-title">
                              {subscriptionData.title}
                            </span>
                          </div>
                          <div className="table-responsive">
                            <table className="table white-bg m-0 mt-3">
                              <tbody>
                                <tr className="table-secondary">
                                  <td>Amount</td>
                                  <td>
                                    {
                                      subscriptionData.subscription_amount_formatted
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>{subscriptionData.description}</td>
                                </tr>
                                <tr className="table-secondary">
                                  <td>Total</td>
                                  <td>
                                    {
                                      subscriptionData.subscription_amount_formatted
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          {this.props.cards.cardDetails.loading ? (
                            "Getting Card Details..."
                          ) : this.props.cards.cardDetails.data.cards.length >
                            0 ? (
                            ""
                          ) : (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card-details-info">
                                  <StripeProvider stripe={this.state.stripe}>
                                    <Elements>
                                      <AddCardSec
                                        cardAddedStatusChange={
                                          this.cardAddedStatusChange
                                        }
                                      />
                                    </Elements>
                                  </StripeProvider>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="align-left">
                              <button
                                type="submit"
                                className="btn btn-primary btn-round"
                                disabled={
                                  this.props.subscriptions.buttonDisable
                                }
                                onClick={(event) =>
                                  this.handleSubmit(event, subscriptionData)
                                }
                              >
                                {this.props.subscriptions
                                  .loadingButtonContent != null
                                  ? this.props.subscriptions
                                      .loadingButtonContent
                                  : "Pay Now"}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.loading != true ? (
                        <div className="card-body">
                          <div className="head">
                            <i className="fas fa-file-invoice mr-2"></i>
                            <span className="head-title">
                              {subscriptionData.title}
                            </span>
                          </div>
                          <div className="table-responsive">
                            <table className="table white-bg m-0 mt-3">
                              <tbody>
                                <tr className="table-secondary">
                                  <td>Amount</td>
                                  <td>
                                    {
                                      subscriptionData.subscription_amount_formatted
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>{subscriptionData.description}</td>
                                </tr>
                                <tr className="table-secondary">
                                  <td>Total</td>
                                  <td>
                                    {
                                      subscriptionData.subscription_amount_formatted
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          {this.props.cards.cardDetails.loading ? (
                            "Getting Card Details..."
                          ) : this.props.cards.cardDetails.data.cards.length >
                            0 ? (
                            ""
                          ) : (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card-details-info">
                                  <StripeProvider stripe={this.state.stripe}>
                                    <Elements>
                                      <AddCardSec
                                        cardAddedStatusChange={
                                          this.cardAddedStatusChange
                                        }
                                      />
                                    </Elements>
                                  </StripeProvider>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="align-left">
                              <button
                                type="submit"
                                className="btn btn-primary btn-round"
                                disabled={
                                  this.props.subscriptions.buttonDisable
                                }
                                onClick={(event) =>
                                  this.handleSubmit(event, subscriptionData)
                                }
                              >
                                {this.props.subscriptions
                                  .loadingButtonContent != null
                                  ? this.props.subscriptions
                                      .loadingButtonContent
                                  : "Pay Now"}
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
  cards: state.cards,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionInvoice);
