import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCardGroup,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";
import "./Subscription.css";
import { connect } from "react-redux";
import SectionContainer from "../helper/SectionContainer";
import { fetchSubscriptionStart } from "../../store/actions/SubscriptionAction";

class SubscriptionList extends Component {
  componentDidMount() {
    if (this.props.subscriptions.subscription.loading)
      this.props.dispatch(fetchSubscriptionStart());
  }
  handleSubmit = (event, subscription) => {
    event.preventDefault();
    this.props.history.push(
      `/subscriptions-invoice/${subscription.subscription_id}`,
      subscription
    );
  };
  sendSubscriptionHistory = (event) => {
    event.preventDefault();
    this.props.history.push("/myplans");
  };
  render() {
    const { subscription } = this.props.subscriptions;
    return (
      <div className="subscriptions-list-sec sm-padding">
        <div className="container">
          <MDBBtn
            className="margin-left-zero"
            color="danger"
            onClick={this.sendSubscriptionHistory}
          >
            Subscription History
          </MDBBtn>
          <div className="row">
            {subscription.loading
              ? "Loading..."
              : subscription.data.length > 0
              ? subscription.data.map((subscription) => (
                  <div className="col-md-4 margin-btm">
                    <div className="card card-user">
                      <div className="card-header">
                        <h5 className="card-title">{subscription.title}</h5>
                      </div>
                      <div className="card-body">
                        <div class="subscription-price">
                          <p>plan</p>
                          <h4>
                            {subscription.subscription_amount_formatted} /{" "}
                            {subscription.plan_text}
                          </h4>
                        </div>
                        <div className="subscription-desc">
                          <p>
                            <i className="fas fa-users text-muted"></i>{" "}
                            {subscription.no_of_users_formatted}
                            <span className="float-right">
                              <i className="fas fa-clock text-muted"></i>{" "}
                              {subscription.no_of_hrs_formatted}
                            </span>
                          </p>
                          <h5>{subscription.description}</h5>
                        </div>
                        <div className="row">
                          <div className="update ml-auto mr-auto">
                            <button
                              type="submit"
                              className="btn btn-primary btn-round"
                              onClick={(event) =>
                                this.handleSubmit(event, subscription)
                              }
                            >
                              Pay Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : "No Data Found"}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionList);
