import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import CallsReducer from "./CallsReducer";
import MeetingReducer from "./MeetingReducer";
import notifyReducer from "react-redux-notify";
import SubscriptionReducer from "./SubscriptionReducer";
import CardsReducer from "./CardsReducer";

export default combineReducers({
  users: UserReducer,
  changePassword: ChangePasswordReducer,
  storeCall: CallsReducer,
  meeting: MeetingReducer,
  notifications: notifyReducer,
  subscriptions: SubscriptionReducer,
  cards: CardsReducer,
});
