import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import "./Subscription.css";
import SectionContainer from "../helper/SectionContainer";

class SubscriptionHistory extends Component {
  state = {};
  render() {
    return <div></div>;
  }
}

export default SubscriptionHistory;
