import React, { Component } from "react";
import SideBar from "./header/SideBar";
import NavBar from "./header/NavBar";
import { MDBFooter } from "mdbreact";
import Footer from "./footer/Footer";
import { Notify } from "react-redux-notify";

class MainLayout extends Component {
  state = {
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = (collID) => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: "" });
  };

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    );

    const { collapseID } = this.state;

    return (
      <div className="flyout">
        <NavBar />
        {collapseID && overlay}
        <main style={{ marginTop: "4rem" }}>
          <Notify position="BottomLeft" />
          <div className="">{React.cloneElement(this.props.children)}</div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default MainLayout;
